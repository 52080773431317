
import Vue from 'vue'
import gsap from 'gsap';
import playWhenInView from '@/modules/@common/animations/play-when-in-view';

const mainTimeline = gsap.timeline();

export default Vue.extend({
  name: 'TopLeftCli',
  mounted() {
    mainTimeline.add(this.animateUi(),0);
    playWhenInView(mainTimeline,`#${this.uniqueId}`)
  },
  props: {
    uniqueId:{
      type: String,
      default: 'top-left-cli',
    }
  },
  beforeDestroy() {
    mainTimeline.kill();
  },
  methods: {
    animateUi() {
      return gsap.timeline({ repeat: -1 })
      .add(gsap.timeline({ repeat: -1 })
        .to(`#${this.uniqueId} #cli`, { opacity: 1, duration: 0.8, ease: "power1.inOut" })
        .to(`#${this.uniqueId} #cursor`, { x: 119, duration: 1.4, ease: "steps(12)" })
        .to(`#${this.uniqueId} #command-block`, { width: 1, x: 120, duration: 1.4, ease: "steps(12)" }, "<")
        .to(`#${this.uniqueId} #cursor`, { fill: "#09161F", duration: 0.4, delay: 0.4, ease: "steps(1)" })
        .to(`#${this.uniqueId} #verifying`, { opacity: 1, duration: 0.4, ease: "steps(1)" }, "<")
        .to(`#${this.uniqueId} #validating`, { opacity: 1, duration: 0.4, delay: 0.25, ease: "steps(1)" })
        .to(`#${this.uniqueId} #uploading`, { opacity: 1, duration: 0.4, delay: 0.25, ease: "steps(1)" })
        .to(`#${this.uniqueId} #success`, { opacity: 1, duration: 0.4, delay: 0.25, ease: "power1.inOut" })
        .to(`#${this.uniqueId} #og-keys`, { opacity: 0, duration: 0.4, ease: "power1.inOut" })
        .to(`#${this.uniqueId} #prog-cs`, { width: 16, duration: 0.8, ease: "power1.out" })
        .to(`#${this.uniqueId} #prog-de`, { width: 9, duration: 0.8, ease: "power1.out" }, "<")
        .to(`#${this.uniqueId} #prog-po`, { width: 12, duration: 0.8, ease: "power1.out" }, "<")
        .to(`#${this.uniqueId} #new-keys`, { opacity: 1, scale: 1.3, fill: "#47B52B", duration: 0.6, ease: "power1.inOut" }, "<")
        .to(`#${this.uniqueId} #new-keys`, { scale: 1, duration: 0.6, fill: "#64707B", ease: "power1.out" })
        .to(`#${this.uniqueId} #pointer`, { x: 4, y: -218, duration: 0.8, ease: "power1.out" })
        .to(`#${this.uniqueId} #pointer`, { rotate: -10, scale: 0.9, duration: 0.2, delay: 0.2, ease: "power1.out" })
        .to(`#${this.uniqueId} #cli`, { opacity: 0, scale: 0.95, duration: 0.4, ease: "power1.inOut" }, "<")
        .to(`#${this.uniqueId} #pointer`, { rotate: 0, scale: 1, duration: 0.2, ease: "power1.inOut" })
        .to(`#${this.uniqueId} #pointer`, { x: 80, y: -180, duration: 0.6, ease: "power1.inOut" })
        .to(`#${this.uniqueId} #og-keys`, { opacity: 1, duration: 0.6, delay: 8, ease: "power1.out" })
        .to(`#${this.uniqueId} #new-keys`, { opacity: 0, duration: 0.6, ease: "power1.out" }, "<")
        .to(`#${this.uniqueId} #prog-cs-2`, { opacity: 1, duration: 0.6, ease: "power1.out" }, "<")
        .to(`#${this.uniqueId} #prog-de-2`, { opacity: 1, duration: 0.6, ease: "power1.out" }, "<")
        .to(`#${this.uniqueId} #prog-po-2`, { opacity: 1, duration: 0.6, ease: "power1.out" }, "<")
        .duration(18)
      , 0);
    }
  }
})
